import { getCurrentSessionId } from "utils/getCurrentSessionId";
import { isTokenExpired } from "utils/isTokenExpired";
import { redirectToLogout } from "utils/redirectToLogout";
import Axios, { publicRequest, requestWithSpecialToken } from "./api";
import { m } from "framer-motion";

let refreshAccessTokenPromise = null;

const auth = (set, get) => ({
  isLoggedIn: false,
  isAccountActivate: false,
  auth: null,
  resetPasswordMsg: null,
  isTokenValid: false,
  resendEmailLoading: false,
  changeEmailLoading: false,
  verifyExistingEmailLoading: false,
  ConfirmEmailLoading: false,
  isPhoneVerified: false,
  resendOTPCodeLoading: false,
  isPinCreated: false,
  isLocationAdded: false,
  freezeAccountDelay: null,
  userPayment: {},

  setFreezeAccountDelay: (delay, paymentPercentage) =>
    set({
      freezeAccountDelay: delay,
      userPayment: paymentPercentage,
    }),
  login: payload => set({ isLoggedIn: true, auth: payload }),
  updateAccessToken: token =>
    set(state => ({
      auth: { ...state.auth, access_token: token },
    })),

  logout: async () => {
    const currentSessionId = getCurrentSessionId();

    try {
      await Axios.post("/logout", null, {
        params: { sessionId: currentSessionId },
      });
      // set({ isLoggedIn: false, auth: null });
    } catch (error) {
    } finally {
      const colorMode = localStorage.getItem("chakra-ui-color-mode");
      const theme = localStorage.getItem("theme");
      const selectedLanguage = localStorage.getItem("i18nextLng");
      window.localStorage.clear();
      window.localStorage.setItem("chakra-ui-color-mode", colorMode);
      window.localStorage.setItem("theme", theme);
      window.localStorage.setItem("i18nextLng", selectedLanguage);

      window.location.href.replace("/login");
      get().deleteEverything();
    }
  },

  verifyAccountActivation: async token => {
    try {
      let res;
      if (token) {
        res = await requestWithSpecialToken(
          "/dashboard/isAccountActivate",
          "get",
          token
        );
      } else {
        res = await Axios.get("/dashboard/isAccountActivate");
      }

      if (res.data) {
        set({ isAccountActivate: res.data?.isAccountActivate });
      }
      return res;
    } catch (error) {
      redirectToLogout();
      return error.response;
    }
  },

  verifyLocation: async token => {
    try {
      let res;
      if (token) {
        res = await requestWithSpecialToken(
          "/usersLocations/verify",
          "get",
          token
        );
      } else {
        res = await Axios.get("/usersLocations/verify");
      }

      if (res.data) {
        set({ isLocationAdded: res.data?.isExist });
      }
      return res;
    } catch (error) {
      redirectToLogout();
      return error.response;
    }
  },

  activateEmail: async token => {
    try {
      const res = await publicRequest.post(`/login/validEmail?token=${token}`);
      return res;
    } catch (error) {
      throw error;
    }
  },

  resendActivationEmail: async () => {
    set({ resendEmailLoading: true });
    try {
      const res = await Axios.get(`/dashboard/resendActivationEmail`);
      set({ resendEmailLoading: false });
      return res;
    } catch (error) {
      set({ resendEmailLoading: false });
      return error.response;
    }
  },

  changeEmail: async values => {
    set({ changeEmailLoading: true });
    try {
      const res = await Axios.put("/settings/updateEmail", {
        newEmail: values.newEmail,
        password: values.password,
      });
      set({ changeEmailLoading: false });
      return res;
    } catch (err) {
      set({ changeEmailLoading: false });
      return err.response;
    }
  },

  verifyExistingEmail: async () => {
    set({ verifyExistingEmailLoading: true });
    try {
      const res = await Axios.get(`/settings/sendVerificationEmail`);
      set({ verifyExistingEmailLoading: false });
      return res;
    } catch (err) {
      set({ verifyExistingEmailLoading: false });
      return err.response;
    }
  },

  confirmChangeEmail: async pin => {
    set({ ConfirmEmailLoading: true });
    try {
      const res = await Axios.post(
        `/settings/confirmChangedEmail?token=${pin}`
      );
      set({ ConfirmEmailLoading: false });
      return res;
    } catch (err) {
      set({ ConfirmEmailLoading: false });
      return err.response;
    }
  },

  confirmExistingEmail: async pin => {
    set({ ConfirmEmailLoading: true });
    try {
      const res = await Axios.post(
        `/settings/confirmExistingEmail?token=${pin}`
      );
      set({ ConfirmEmailLoading: false });
      return res;
    } catch (err) {
      set({ ConfirmEmailLoading: false });
      return err.response;
    }
  },

  signIn: async values => {
    // check if the user is logged in before
    const token = get().auth?.access_token;

    if (token && !isTokenExpired(token)) {
      window.location.href = "/dashboard";
      return;
    }

    try {
      const res = await publicRequest.post(`/login`, values);

      if (res.status === 200) {
        if (res.data?.access_token) {
          localStorage.setItem("token", res.data?.access_token);
          localStorage.setItem("refresh_token", res.data?.refresh_token);
          localStorage.setItem("sessionId", res.data?.sessionId);
          await Promise.all([
            get().verifyAccountActivation(res.data?.access_token),
            get().verifyPhone(res.data?.access_token),
            get().verifyPin(res.data?.access_token),
            get().verifyLocation(res.data?.access_token),
          ]);

          get().login(res.data);
          // if (get().isAccountActivate) {
        } else if (res?.data?.phone || res?.data.email) {
          window.location.href = `/verifyCode?username=${res?.data?.username}&rememberMe=${values?.rememberMe}&phone=${res?.data.phone}&email=${res?.data.email}`;
        } else {
          window.location.href = `/verifyPin?token=${res?.data?.token}&username=${res?.data?.username}&rememberMe=${values?.rememberMe}`;
        }

        // }
      } else {
        throw new Error();
      }
      return res;
    } catch (error) {
      //console.log(error.response);
      throw error;
    }
  },

  signInWithGoogle: async (access_token, refresh_token, sessionId) => {
    localStorage.setItem("token", access_token);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("sessionId", sessionId);
    await Promise.all([
      get().verifyAccountActivation(access_token),
      get().verifyPhone(access_token),
      get().verifyPin(access_token),
      get().verifyLocation(access_token),
    ]);
    get().login({ access_token, refresh_token, sessionId });
  },

  signup: async values => {
    try {
      const res = await publicRequest.post(`/home/signup`, values);
      //console.log(res);

      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  forgotPassword: async email => {
    try {
      const res = await publicRequest.post(`/login/forgot?email=${email}`);
      return res;
    } catch (error) {
      throw error;
    }
  },
  checkValidationOfToken: async token => {
    try {
      await publicRequest.post(`/login/validToken?token=${token}`);
    } catch (error) {
      throw error;
    }
  },
  changeForgotPassword: async (token, password) => {
    try {
      const res = await publicRequest.post(
        `/login/reset?token=${token}&newPassword=${password}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  signupBySuper: async (values, referralLink) => {
    try {
      const res = await publicRequest.post(
        `/home/signup/${referralLink}`,
        values
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  verifyPhone: async token => {
    try {
      let res;
      if (token) {
        res = await requestWithSpecialToken(
          "/dashboard/isPhoneVerified",
          "get",
          token
        );
      } else {
        res = await Axios.get("/dashboard/isPhoneVerified");
      }

      if (res?.data === true) {
        set({ isPhoneVerified: true });
      } else {
        set({ isPhoneVerified: false });
      }

      return res;
    } catch (error) {
      redirectToLogout();
      return error.response;
    }
  },

  verifyPin: async token => {
    try {
      let res;
      if (token) {
        res = await requestWithSpecialToken(
          "/dashboard/isPinCreated",
          "get",
          token
        );
      } else {
        res = await Axios.get("/dashboard/isPinCreated");
      }

      set({ isPinCreated: res.data?.isPinCreated });

      return res;
    } catch (error) {
      redirectToLogout();
      return error.response;
    }
  },

  getNewAccessToken: async () => {
    const refreshToken = get().auth?.refresh_token; // Destructuring for clarity

    // Redirect to logout if no refresh token is available or it is expired
    if (!refreshToken || isTokenExpired(refreshToken)) {
      redirectToLogout();
      return null;
    }

    try {
      // Check if an access token refresh is already in progress
      if (!refreshAccessTokenPromise) {
        // Mark the start of a refresh token operation by creating a promise
        refreshAccessTokenPromise = (async () => {
          const res = await publicRequest.post("/login/refreshToken", {
            refreshToken,
          });
          const newAccessToken = res.data?.access_token;

          if (newAccessToken) {
            get().updateAccessToken(newAccessToken); // Update the access token in your state
            localStorage.setItem("token", newAccessToken); // Optionally, update localStorage or use secure cookie storage
          }

          return newAccessToken;
        })();
      }

      // Wait for the refresh operation to complete, whether it was started by this call or a previous one
      return await refreshAccessTokenPromise;
    } catch (error) {
      redirectToLogout();
      return null;
    } finally {
      setTimeout(() => (refreshAccessTokenPromise = null), 10);
    }
  },

  resendEmailVerificationCode: async username => {
    await publicRequest.post("/login/resendOtpByEmail", {
      username,
    });
  },

  resendPhoneVerificationCode: async username => {
    await publicRequest.post("/login/resendOtp", {
      username,
    });
  },

  signInWithOTPCode: async values => {
    // check if the user is logged in before
    const token = get().auth?.access_token;
    if (token && !isTokenExpired(token)) {
      window.location.href = "/dashboard";
      return;
    }

    const res = await publicRequest.post(`/login/validOtp`, {
      verificationCode: values.code,
      username: values.username,
      rememberMe: values.rememberMe === "true",
    });

    localStorage.setItem("token", res.data?.access_token);
    localStorage.setItem("refresh_token", res.data?.refresh_token);
    await Promise.all([
      get().verifyAccountActivation(res.data?.access_token),
      get().verifyPhone(res.data?.access_token),
      get().verifyPin(res.data?.access_token),
      get().verifyLocation(res.data?.access_token),
    ]);
    get().login(res.data);

    return res;
  },

  signInWithPINCode: async values => {
    // check if the user is logged in before
    const token = get().auth?.access_token;
    if (token && !isTokenExpired(token)) {
      window.location.href = "/dashboard";
      return;
    }

    const res = await publicRequest.post("/login/validPinCode", {
      pin: values.pin,
      username: values.username,
      token: values.token,
      rememberMe: values.rememberMe === "true",
    });

    localStorage.setItem("token", res.data?.access_token);
    localStorage.setItem("refresh_token", res.data?.refresh_token);
    await Promise.all([
      get().verifyAccountActivation(res.data?.access_token),
      get().verifyPhone(res.data?.access_token),
      get().verifyPin(res.data?.access_token),
      get().verifyLocation(res.data?.access_token),
    ]);

    get().login(res.data);

    return res;
  },
});

export default auth;
