import { Button } from "@/components/ui/Button";
import UserPaymentPercentagePopover from "@/pages/dashboard/components/UserPaymentPercentagePopover";
import useStore from "@/store";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { PiWarningCircleBold } from "react-icons/pi";
import AccountFrozen from "./AccountFrozen";

dayjs.extend(duration);

const FreezeAccountWarning = () => {
  const { t } = useTranslation("layout");
  const freezeAccountDelay = useStore(state => state.freezeAccountDelay);
  const userPayment = useStore(state => state.userPayment);
  const [timer, setTimer] = useState(freezeAccountDelay);
  const currentUser = useStore(state => state.currentUser);
  const isAccountFrozen = currentUser?.account?.frozen;

  const formattedDelay = useMemo(() => {
    const duration = dayjs.duration(timer * 1000);

    const totalHours = Math.floor(duration.asHours())
      .toString()
      .padStart(2, "0");
    const minutes = duration.minutes().toString().padStart(2, "0");
    const seconds = duration.seconds().toString().padStart(2, "0");

    return `${totalHours}:${minutes}:${seconds}`;
  }, [timer]);

  useEffect(() => {
    if (!timer || timer <= 0 || isAccountFrozen) return;

    const interval = setInterval(() => {
      setTimer(prevTimer => Math.max(prevTimer - 1, 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    setTimer(freezeAccountDelay);
  }, [freezeAccountDelay]);

  if (isAccountFrozen || timer == 0 || timer < 0) return <AccountFrozen />;
  if (!timer) return null;

  return (
    <div className="min-h-[36px] py-1 mx-auto px-2 sm:px-4 md:px-8 max-w-7xl flex items-center justify-between bg-destructive w-full text-destructive-foreground">
      <span className="max-[450px]:text-sm mx-auto flex items-center text-base">
        <PiWarningCircleBold className="text-[1.3rem] flex-shrink-0 me-2" />
        <span>
          <Trans
            ns="layout"
            i18nKey="freeze_account_warning"
            values={{
              delay: formattedDelay,
            }}
            components={{
              span: <span className="font-semibold w-[80px] text-center" />,
            }}
          />
          &nbsp; | &nbsp;
          <UserPaymentPercentagePopover
            userPayment={userPayment}
            trigger={
              <Button
                variant="link"
                className="p-0 inline text-destructive-foreground underline data-[state=open]:ring-0 data-[state=open]:ring-offset-0 h-fit"
              >
                {t("payed_percentage", {
                  percentage: userPayment.percentage || 0,
                })}
              </Button>
            }
          />
        </span>
      </span>
    </div>
  );
};

export default FreezeAccountWarning;
