import { Badge } from "@/components/ui/Badge";
import useStore from "@/store";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BsInfoLg } from "react-icons/bs";
import { IoIosArrowUp } from "react-icons/io";
import { Button } from "../../../components/ui/Button";
import UserPaymentPercentagePopover from "./UserPaymentPercentagePopover";

export const PercentageBadge = ({ percentage }) => {
  const showBalance = useStore(state => state.showBalance);

  return (
    <Badge
      variant={percentage >= 90 ? "success" : "destructive"}
      size="sm"
      className="flex items-center w-fit"
    >
      <>
        {percentage >= 90 && <IoIosArrowUp className="inline text-[1rem]" />}
        {showBalance ? percentage || 0 : "**"}%
      </>
    </Badge>
  );
};

const UserPaymentPercentage = ({ userPayment }) => {
  const { t } = useTranslation("dashboard");
  const setFreezeAccountDelay = useStore(state => state.setFreezeAccountDelay);
  const { percentage, debtOfWeek, paymentAmount, dayOfWeek } =
    userPayment || {};

  useEffect(() => {
    if (percentage < 90) {
      const todayIndex = new Date().getDay();
      const targetIndex = dayOfWeek % 7;

      // If today or tomorrow matches `dayOfWeek`
      if (todayIndex === targetIndex || (todayIndex + 1) % 7 === targetIndex) {
        const now = dayjs();

        const targetDate = dayjs()
          .add(
            targetIndex >= todayIndex
              ? targetIndex - todayIndex
              : 7 - (todayIndex - targetIndex),
            "day"
          )
          .hour(10)
          .minute(0)
          .second(0)
          .millisecond(0);

        const delay = targetDate.diff(now, "seconds");

        setFreezeAccountDelay(delay, {
          percentage,
          debtOfWeek,
          paymentAmount,
          dayOfWeek,
        });
      } else {
        setFreezeAccountDelay(null, null);
      }
    } else {
      setFreezeAccountDelay(null, null);
    }
  }, [percentage, debtOfWeek, paymentAmount, dayOfWeek, setFreezeAccountDelay]);

  return (
    <div className="flex items-center">
      <PercentageBadge percentage={percentage} />
      <span className="font-semibold text-sm whitespace-nowrap text-muted-foreground flex items-center">
        &nbsp; {t("popovers.debt_info.paid_status")}
      </span>

      <UserPaymentPercentagePopover
        userPayment={userPayment}
        trigger={
          <Button
            size="icon"
            variant="outline"
            className="h-7 w-7 rounded-full ms-2"
            onClick={e => e.stopPropagation()}
          >
            <BsInfoLg className="text-[1rem]" />
          </Button>
        }
      />
    </div>
  );
};

export default UserPaymentPercentage;
