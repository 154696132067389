import Axios from "./api";
import products from "./products";

const client = (set, get) => ({
  client: {
    categories: null,
    subCategories: null,
    products: null,
    networks: null,
    offers: null,
    content: null,
    loading: false,
    networkLoading: false,
    offersLoading: false,
    allSubCategories: null,
  },

  getAllClientNetworks: async () => {
    try {
      set({
        client: { ...get().client, networkLoading: true },
      });
      const res = await Axios.get(`/client/networks`);
      //console.log('networks', res);
      if (res.status === 200) {
        set({
          client: {
            ...get().client,
            networkLoading: false,
            networks: res.data?.content,
          },
        });
      }
    } catch (error) {
      set({
        client: { ...get().client, networkLoading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
  getAllClientOffers: async id => {
    try {
      set({
        client: { ...get().client, offerLoading: true },
      });
      const res = await Axios.get(`/client/offers?networkId=${id}&sort=price`);
      if (res.status === 200) {
        set({
          client: {
            ...get().client,
            offerLoading: false,
            offers: res.data?.content,
          },
        });
      }
      return res;
    } catch (error) {
      set({
        client: { ...get().client, networkLoading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
  clearAllOffers: async () => {
    set({
      client: {
        ...get().client,
        offers: null,
      },
    });
  },
 
  getAllSubCategoriesByCategoryId: async (loding, id, query) => {
    try {
      set({
        client: { ...get().client, loading: true },
      });
      const res = await Axios.get(`/client/subCategories?categoryId=${id}`);
      //console.log('client', res);
      if (res.status === 200) {
        set({
          client: {
            ...get().client,
            content: res.data,
            loading: false,
            subCategories: res.data?.content,
          },
        });
      }
    } catch (error) {
      set({
        client: { ...get().client, loading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },

  getAllSubCategories: async () => {
    try {
      set({
        client: { ...get().client, loading: true },
      });
      const res = await Axios.get("/client/allSubCategories");
      if (res.status === 200) {
        set({
          client: {
            ...get().client,
            content: res.data,
            loading: false,
            allSubCategories: res.data?.content,
          },
        });
      }
    } catch (error) {
      set({
        client: { ...get().client, loading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },

  getAllClientProducts: async id => {
    try {
      set({
        client: { ...get().client, loading: true },
      });
      const res = await Axios.get(
        `/client/products?subCategoryId=${id}&sort=price`
      );
      //console.log('client', res);
      if (res.status === 200) {
        set({
          client: {
            ...get().client,
            content: res.data,
            loading: false,
            products: res.data,
          },
        });
      }
    } catch (error) {
      set({
        client: { ...get().client, loading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },

  resetClientProducts: async () => {
    set({
      client: { ...get().client, products: null, loading: true },
    });
  },
});

export default client;
